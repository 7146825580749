import React, {useEffect, useState, useRef} from "react";
import Viewport from "./componenets/ViewPort/ViewPort";
import RequestManager from "./services/RequestManager";
import {AUTHORIZATION_DATA, PARTNER_CONFIG_DATA} from "./services/RequestDatas";
import i18next from 'i18next';
import './i18n';
import Utils from "./utils/Utils";
import VideoPlayer from "./componenets/ViewPort/VideoPlayer/VideoPlayer";

export const AppContext = React.createContext({});

function App() {
  const params = new URLSearchParams(window.location.search);
  const [siteID, setSiteID] = useState(params.get('partnerID') || 33);
  const [language, setLanguage] = useState(params.get('lang') || 'eng');
  const [oddType, setOddType] = useState(params.get('odd') || 'Decimal');
  const [lastWinsCount, setLastWinsCount] = useState(params.get('lastResultsCount'));
  const [activeGameID, setActiveGameID] = useState(Number(params.get('gameID')) || 16);
  
  const [partnerConfig, setPartnerConfig] = useState();
  const [sportsData, setSportsData] = useState([]);
  const [activeVideoID, setActiveVideoID] = useState(0);
  const [applicationReady, setApplicationReady] = useState(false);
  const [promotionNotShown, setPromotionNotShown] = useState(true);
  const [isVideoPlay, setIsVideoPlay] = useState(false);
  const [isVidioEnd, setIsVideoEnd] = useState(false);

  const store = {
    siteID: {get: siteID, set: setSiteID},
    language: {get: language, set: setLanguage},
    promotionNotShown: {get: promotionNotShown, set: setPromotionNotShown},
    partnerConfig: {get: partnerConfig, set: setPartnerConfig},
    activeGameID: {get: activeGameID, set: setActiveGameID},
    activeVideoID: {get: activeVideoID, set: setActiveVideoID},
    sportsData: {get: sportsData, set: setSportsData},
    oddType: {get: oddType, set: setOddType},
    lastWinsCount: {get: lastWinsCount, set: setLastWinsCount},
    isVideoPlay: {get: isVideoPlay, set: setIsVideoPlay},
    isVidioEnd: {get: isVidioEnd, set: setIsVideoEnd},
  };

  const socket = useRef();

  useEffect(() => {
    changeAppLanguage(language);
    initAuthorization();
  }, [])

  const initAuthorization = () => {
    socket.current = RequestManager.getInstance(siteID).webSocket;
    socket.current.onopen = () => {
      AUTHORIZATION_DATA.params.language = language;
      AUTHORIZATION_DATA.params.site_id = siteID;

      socket.current.send(JSON.stringify(AUTHORIZATION_DATA));
      socket.current.addEventListener(RequestManager.AUTHORIZATION_EVENT, handleAuthorization, {once: true});
    }
  }

  const changeAppLanguage = lang => {
    lang = lang === 'pt-br' ? 'pt_br' : lang;
    i18next.changeLanguage(lang, error => error ? console.log('i18n error: ', error) : null).then();
  }

  const handleAuthorization = ({detail}) => {
    setApplicationReady(true);
    getPartnerConfig();
  }

  const getPartnerConfig = () => {
    socket.current.send(JSON.stringify(PARTNER_CONFIG_DATA));
    socket.current.addEventListener(RequestManager.PARTNER_DATA_EVENT,
      response => parsePartnerConfigData(response.detail.data.data.partner), {once: true});
  }

  const parsePartnerConfigData = data => {
    data = data[Utils.firstKey(data)];

    if (data) {
      if (data['theme_colors']) {
        Utils.applyApplicationTheme(data['theme_colors']);
      }

      setPartnerConfig(data);
    }
  }

  return applicationReady
    ? <AppContext.Provider value={store}>
      {
        !isVideoPlay ? <Viewport /> : <VideoPlayer loading={true}/>
      }
    </AppContext.Provider>
    : <div className='app-loader'/>
}

export default App;
