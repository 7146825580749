import React, { useState } from "react";
import DownCounter from "./DownCounter/DownCounter";
import "./GameContainer.scss";
import LastWins from "./LastWins/LastWins";
import Market from "./Market/Market";
import Utils from "../../utils/Utils";
import { memo } from "react";

function GameContainer({
  startTimes,
  gameData,
  regionId,
  activeGameId,
  isMarketClosed,
  setIsMarketClosed,
}) {
  const [startTimestampServer, setStartTimestampServer] = useState();
  const [animateText, setAnimateText] = useState(false);
  let startTimeKey = Utils.firstKey(startTimes);
  let roundId = startTimes && startTimeKey && startTimes[startTimeKey]["round"];

  return (
    <div className="game-container">
      <DownCounter
        startTimes={startTimes}
        startTimestampServer={startTimestampServer}
        setStartTimestampServer={setStartTimestampServer}
        setAnimateText={setAnimateText}
        setIsMarketClosed={setIsMarketClosed}
      />
      <div className="game-container-content">
        <div className="left-site">
          <div className="game-container-header">
            <p className="game-container-header-round-id">
              Round ID: {roundId}
            </p>
            <h4
              className={`place-bets-text ${
                animateText && !isMarketClosed ? "animate" : ""
              }`}
            >
              {isMarketClosed ? "THE BETS ARE CLOSED" : "PLACE YOUR BETS"}
            </h4>
            <p className="game-container-header-start-time">
              <span>Start time:</span>{" "}
              <span>
                {Utils.toLocalTime(startTimestampServer, false, "HH:mm")}
              </span>
            </p>
          </div>

          <Market
            gameData={gameData}
            regionId={regionId}
            startTimeKey={startTimeKey}
            activeGameId={activeGameId}
          />
        </div>

        <div className="right-site">
          <LastWins startTimes={startTimes} />
        </div>
      </div>
    </div>
  );
}

export default memo(GameContainer);
