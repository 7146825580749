import { memo, useEffect, useState, useContext } from "react";
import { AppContext } from "../../../App";
import { LAST_RESULTS_DATA } from "../../../services/RequestDatas";
import RequestManager from "../../../services/RequestManager";
import "./lastWins.scss";

const LastWins = () => {
  const context = useContext(AppContext);
  const activeGameId = context.activeGameID.get;
  const baseURL = process.env.PUBLIC_URL + "./assets/playersIcons/" + activeGameId;
  const socket = RequestManager.getInstance().webSocket;
  const [lastResults, setLastResults] = useState([]);

  useEffect(() => {
    if (context.sportsData.get.length) {
      LAST_RESULTS_DATA.params.sport_id = activeGameId;
      LAST_RESULTS_DATA.params.main_result_count = context.lastWinsCount.get
      socket.send(JSON.stringify(LAST_RESULTS_DATA));

      socket.addEventListener(
        RequestManager.LAST_RESULTS_EVENT,
        (response) => initLastResultData(response.detail),
        { once: true }
      );
    }
  }, [context.sportsData.get]);

  const initLastResultData = ({ data }) => {
    setLastResults(data.details.latest_results);
  };

  const getWinnersBox = () => {

    return <div className="winners-box">
      {lastResults.length
        ? lastResults.map((item, index) => (
        <div key={index}>
            <p>
              Round ID: <span>{item.round}</span>
            </p>

            <div className="winners">
              {item.info.map((winer, i) => (
                <object data={`${baseURL}/${winer.number}.svg`} key={i}/>
              ))}
            </div>
          </div>
        ))
        : null}
    </div>
  }

  return (
    <div className="last-wins">
      <div className="title">
        <h3>LAST WINS</h3>
      </div>
      {
        getWinnersBox()
      }
    </div>
  );
};

export default memo(LastWins);
