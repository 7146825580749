import React, { memo, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { GET_VIDEO_URL } from "../../../services/RequestDatas";
import RequestManager from "../../../services/RequestManager";
import "./VideoPlayer.scss";

function VideoPlayer({ loaded }) {
  const socket = RequestManager.getInstance().webSocket;
  const params = new URLSearchParams(window.location.search);
  const videoID = params.get("videoId");
  const provider = params.get("provider");
  const context = useContext(AppContext);
  const [videoURL, setVideoURL] = useState();
  const playerClassName = loaded
    ? "video-player loaded"
    : "video-player loading";

  useEffect(() => {
    GET_VIDEO_URL.params.video_id = Number(videoID);
    GET_VIDEO_URL.params.provider = Number(provider);
    socket.send(JSON.stringify(GET_VIDEO_URL));

    socket.addEventListener(
      RequestManager.GET_VIDEO_URL_EVENT,
      (response) => initVideoUrl(response.detail),
      { once: true }
    );
  }, []);

  const initVideoUrl = ({ data }) => {
    setVideoURL(data);
  };

  return (
    <div className="video-item">
      <div className="player-container">
        <video
          className={playerClassName}
          src={videoURL}
          onEnded={() => {
            context.isVidioEnd.set(true);
            context.isVideoPlay.set(false);
          }}
          autoPlay
          muted
        />
      </div>
    </div>
  );
}

export default memo(VideoPlayer);
