import moment from "moment";
import React, { useEffect, useContext, useState, memo } from "react";
import { AppContext } from "../../../App";
import { GET_SERVER_TIME } from "../../../services/RequestDatas";
import RequestManager from "../../../services/RequestManager";
import Utils from "../../../utils/Utils";
import "./DownCounter.scss";

function DownCounter({
  startTimes,
  setStartTimestampServer,
  startTimestampServer,
  setAnimateText,
  setIsMarketClosed,
}) {
  const context = useContext(AppContext);
  const [remainingTime, setRemainingTime] = useState();
  const [currentTimeServer, setCurrentTimeServer] = useState();
  const socket = RequestManager.getInstance().webSocket;

  useEffect(() => {
    if (context.sportsData.get.length) {
      initTimeOut();
      getCurrentTime();
    }
  }, [context.sportsData.get]);

  useEffect(() => {
    if (currentTimeServer) {
      updateRemainingTime();
    }
  }, [currentTimeServer]);

  const initTimeOut = () => {
    let startTimeKey = Utils.firstKey(startTimes);

    if (startTimeKey && startTimes[startTimeKey]) {
      setStartTimestampServer(startTimes[startTimeKey]["start_ts"]);
    }
  };

  const updateCountDown = (m, s) => {
    let time = m * 60 + s;
    let refreshIntervalId = setInterval(updateTime, 1000);
    function updateTime() {
      const minutes = Math.floor(time / 60);
      let seconds = time % 60;
      let remainingTime = `${minutes < 10 ? "0" : ""}${minutes}:${
        seconds < 10 ? "0" : ""
      }${seconds}`;

      console.log(remainingTime, "remainingTime 2");
      setRemainingTime(remainingTime);
      time--;

      if (remainingTime[0] === "0") {
        if (remainingTime === "00:30") {
          setAnimateText(true);
        }

        if (remainingTime === "00:00") {
          context.isVideoPlay.set(true);
          setAnimateText(false);
          setIsMarketClosed(false);
        }

        if (time < 0) {
          clearInterval(refreshIntervalId);
        }
      }
    }
  };

  const getCurrentTime = () => {
    socket.send(JSON.stringify(GET_SERVER_TIME));
    socket.addEventListener(
      RequestManager.GET_SERVER_TIME_EVENT,
      parseGameTimesData,
      { once: true }
    );
  };

  const parseGameTimesData = ({ detail }) => {
    setCurrentTimeServer(detail.data.details);
  };

  const updateRemainingTime = () => {
    if (startTimestampServer && currentTimeServer) {
      const currentTime = moment.unix(currentTimeServer).format("HH:mm:ss");
      const startTime = moment.unix(startTimestampServer).format("HH:mm:ss");
      const remainingTime = Utils.getTimesDifference(
        startTime,
        currentTime
      ).split(":");

      console.log(remainingTime, "remainingTime");
      if (startTime > currentTime) {
        updateCountDown(Number(remainingTime[0]), Number(remainingTime[1]));
      }
    }
  };

  return (
    <div className="down-counter-container">
      <div className="counter">{remainingTime}</div>
    </div>
  );
}

export default memo(DownCounter);
