import React, { useEffect, useContext, memo, useState, useRef } from "react";
import {
  MARKET_DATA,
  SPORTS_DATA,
  UNSUBSCRIBE_DATA,
} from "../../services/RequestDatas";
import RequestManager from "../../services/RequestManager";
import GameContainer from "../GameContainer/GameContainer";
import { AppContext } from "../../App";
import Utils from "../../utils/Utils";
import "./Viewport.scss";

function Viewport() {
  const socket = RequestManager.getInstance().webSocket;
  const baseURL = process.env.PUBLIC_URL + "./assets/backgrounds/";
  const context = useContext(AppContext);
  const activeGameID = context.activeGameID.get;
  const [startTimes, setStartTimes] = useState();
  const [gameData, setGameData] = useState();
  const [isMarketClosed, setIsMarketClosed] = useState(false);
  const allSportsData = [];
  const regionRef = useRef();

  const videoIDS = {
    VirtualFootballLeague: 16,
    VirtualFootball: 9,
    PenaltyKicks: 10,
    VirtualGreyhounds: 11,
    VirtualBicycle: 15,
    VirtualHorseRacing: 13,
    DragRacing: 21,
    MarbleRacing: 20,
  };

  useEffect(() => {
    SPORTS_DATA.params.where.sport.id["@in"] = [activeGameID];
    socket.send(JSON.stringify(SPORTS_DATA));

    socket.addEventListener(
      RequestManager.SPORTS_DATA_EVENT,
      (response) => initSportsData(response.detail),
      { once: true }
    );
    socket.addEventListener(RequestManager.AUTO_GAME_DATA, (response) =>
      handleGamesDataUpdate(response.detail)
    );

    return () => unsubscribeFromSportsData();
  }, []);

  useEffect(() => {
    if (context.sportsData.get.length && startTimes) {
      getMarketData(Utils.firstKey(startTimes), regionRef);
    }
  }, [context.sportsData.get]);

  const initSportsData = (response) => {
    SPORTS_DATA.subID = response.data["subid"];
    const data = response.data.data.sport;

    if (Object.keys(data).length === 0) {
      return;
    }

    for (let key in data) {
      if (data[key]) {
        const sportData = data[key];
        const regionData = sportData["region"];

        sportData["game"] = regionData
          ? Object.values(regionData)[0]["game"]
          : {};
        sportData["region_id"] = regionData
          ? Object.values(regionData)[0]["id"]
          : {};

        allSportsData.push(sportData);
        regionRef.current = Object.values(regionData)[0]["id"];
      }
    }

    setStartTimes({ ...allSportsData[0].game });

    if (activeGameID) {
      const activeGameData = allSportsData.find((a) => a.id === activeGameID);

      updateSportsData(allSportsData);
      context.activeVideoID.set(videoIDS[activeGameData["alias"]]);
    }
  };

  const getMarketData = (gameId, regionRef) => {
    MARKET_DATA.params.where.game["id"] = Number(gameId);
    MARKET_DATA.params.where.region["id"] = regionRef.current;
    MARKET_DATA.params.where.sport["id"] = activeGameID;

    socket.send(JSON.stringify(MARKET_DATA));
    socket.addEventListener(
      RequestManager.MARKET_DATA_EVENT,
      (response) => initMarketData(response.detail),
      { once: true }
    );

    socket.addEventListener(RequestManager.AUTO_MARKET_DATA, (response) =>
      handleMarketDataUpdate(response.detail)
    );
  };

  const handleMarketDataUpdate = (data) => {
    const isMarketClosed = JSON.stringify(data).includes("null");

    setIsMarketClosed(isMarketClosed);
  };

  const initMarketData = ({ data }) => {
    setGameData(data.data.sport[activeGameID]);
  };

  const updateSportsData = (updatedSportsData) => {
    console.log(777);
    const orderedSportsData = [...updatedSportsData];
    orderedSportsData.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
    context.sportsData.set([orderedSportsData]);
  };

  const unsubscribeFromSportsData = () => {
    UNSUBSCRIBE_DATA.params.subid = SPORTS_DATA.subID;
    socket.send(JSON.stringify(UNSUBSCRIBE_DATA));
  };

  /**
   * Add or Remove the sports data depending on update type.
   * @param response AUTO(live) SPORTS DATA UPDATE
   */
  const handleGamesDataUpdate = (response) => {
    if (response["sport"]) {
      Object.keys(response["sport"]).map((key) => {
        const regionData = response["sport"][key]["region"];
        const gameData = regionData[Utils.firstKey(regionData)];
        updateGameData(parseInt(key), gameData);
      });
    } else {
      updateGameData(activeGameID, response);
    }
  };

  const updateGameData = (gameID, gameData) => {
    const updates = [];
    let isDataRemoved = false;
    gameData = Object.keys(gameData).map((key) => updates.push(gameData[key]));
    // CHECK AND SET GAME UPDATE.
    const currentSportsData = [...allSportsData];
    updates.map((update) => {
      Object.keys(update).map((matchID) => {
        currentSportsData.map((sportData) => {
          if (parseInt(sportData["id"]) === gameID) {
            if (update[matchID] === null) {
              isDataRemoved = true;
              // IF IS UPDATE TO REMOVE GAME.
              let currentData = sportData["game"];
              console.log(currentData);
              delete currentData[matchID];
              setStartTimes(currentData);
            } else {
              // IF IS UPDATE TO ADD NEW GAME.
              sportData["game"][matchID] = update[Utils.firstKey(update)];
            }
          }
        });
      });
    });
    if (isDataRemoved) {
      updateSportsData(currentSportsData);
    }
  };

  return (
    <div className="viewport-container">
      <GameContainer
        startTimes={startTimes}
        gameData={gameData}
        regionId={regionRef.current}
        activeGameId={activeGameID}
        isMarketClosed={isMarketClosed}
        setIsMarketClosed={setIsMarketClosed}
      />
      <img
        className="viewport-background"
        src={baseURL + activeGameID + ".png"}
        alt=""
      />
    </div>
  );
}

export default memo(Viewport);
