import Utils from "../utils/Utils";

export const AUTHORIZATION_RID = Utils.getUniqNumber().toString();
export const SPORTS_RID = Utils.getUniqNumber().toString();
export const GAME_RID = Utils.getUniqNumber().toString();
export const REGION_RID = Utils.getUniqNumber().toString();
export const PROFILE_RID = Utils.getUniqNumber().toString();
export const BONUS_RID = Utils.getUniqNumber().toString();
export const CURRENT_LEAGUE_RID = Utils.getUniqNumber().toString();
export const MARKET_RID = Utils.getUniqNumber().toString();
export const LEAGUE_ROUNDS_RID = Utils.getUniqNumber().toString();
export const WRESTLING_RID = Utils.getUniqNumber().toString();
export const OPENED_BETS_COUNT_RID = Utils.getUniqNumber().toString();
export const OPENED_BETS_RID = Utils.getUniqNumber().toString();
export const PARTNER_CONFIG_RID = Utils.getUniqNumber().toString();
export const CASH_OUT_RID = Utils.getUniqNumber().toString();
export const UNSUBSCRIBE_RID = Utils.getUniqNumber().toString();
export const LAST_RESULTS_RID = Utils.getUniqNumber().toString();
export const GET_SERVER_TIME_RID = Utils.getUniqNumber().toString();
export const GET_VIDEO_URL_RID = Utils.getUniqNumber().toString();
export const AUTO_DATA_UPDATE_RID = "0";
export const SWARM_LOGIN_RID = "1";

const AUTHORIZATION_DATA = {
  command: "request_session",
  params: {
    site_id: "4",
    language: "eng",
  },
  rid: AUTHORIZATION_RID,
};

const PARTNER_CONFIG_DATA = {
  command: "get",
  params: {
    source: "partner.config",
    what: { partner: [] },
    subscribe: true,
  },
  rid: PARTNER_CONFIG_RID,
};

const SPORTS_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      sport: ["id", "name", "alias", "order"],
      game: ["@count", "start_ts", "text_info", "round"],
      region: ["id"],
    },
    where: {
      game: { "@or": [{ type: 0 }, { visible_in_prematch: true, type: 4 }] },
      sport: {
        id: {
          "@in": [3],
        },
      },
    },
    subscribe: true,
  },
  subID: null,
  rid: SPORTS_RID,
};

const LEAGUE_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      competition: [
        "id",
        "name",
        "order",
        "info",
        "video_id",
        "provider",
        "info",
      ],
    },
    where: {
      game: { "@or": [{ type: 0 }, { visible_in_prematch: true, type: 4 }] },
      sport: { id: 1 },
    },
    subscribe: true,
  },
  subID: null,
  rid: CURRENT_LEAGUE_RID,
};

const UNSUBSCRIBE_DATA = {
  command: "unsubscribe",
  params: {
    subid: "6166728670616548707",
  },
  rid: UNSUBSCRIBE_RID,
};

const REGION_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      region: ["id", "name"],
      competition: ["id", "name"],
    },
    where: {
      sport: {
        id: 1,
      },
    },
  },
  rid: REGION_RID,
};

const LAST_RESULTS_DATA = {
  command: "get_last_results",
  params: {
    sport_id: 3,
    main_result_count: 5,
    details_result_count: 0,
  },
  rid: LAST_RESULTS_RID,
};

const MARKET_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      sport: ["id", "alias"],
      competition: ["id", "name"],
      region: ["id"],
      game: [],
      market: [],
      event: [],
    },
    where: {
      game: { id: 1123279 },
      sport: { id: 3 },
      region: { id: 1 },
    },
    subscribe: true,
  },
  subID: null,
  rid: MARKET_RID,
};

const LEAGUE_ROUNDS_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      game: [
        "game_number",
        "team1_name",
        "team2_name",
        "id",
        "start_ts",
        "text_info",
        "info",
        "round",
        "homescore",
        "awayscore",
      ],
    },
    where: {
      competition: { id: 20 },
    },
    subscribe: true,
  },
  subID: null,
  rid: LEAGUE_ROUNDS_RID,
};

const GET_SERVER_TIME = {
  command: "get_server_time",
  params: {},
  rid: GET_SERVER_TIME_RID,
};

const GET_VIDEO_URL = {
  command: "video_url",
  params: {
    video_id: 15,
    provider: 16,
    use_hls: true,
  },
  rid: GET_VIDEO_URL_RID,
};

export {
  AUTHORIZATION_DATA,
  PARTNER_CONFIG_DATA,
  SPORTS_DATA,
  REGION_DATA,
  LAST_RESULTS_DATA,
  MARKET_DATA,
  LEAGUE_DATA,
  LEAGUE_ROUNDS_DATA,
  UNSUBSCRIBE_DATA,
  GET_SERVER_TIME,
  GET_VIDEO_URL,
};
