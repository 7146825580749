import { memo } from "react";
import Utils from "../../../utils/Utils";
import "./market.scss";

const Market = ({ gameData, regionId, startTimeKey, activeGameId }) => {
  const baseURL =
    process.env.PUBLIC_URL + "./assets/playersIcons/" + activeGameId;
  const competition = gameData?.region[regionId].competition;
  const headerItem = [
    "1st",
    "2nd",
    "3rd",
    "1st/2nd",
    "1st/3rd",
    "2nd/3rd",
    "1st/2nd/3rd",
  ];
  const marketData =
    competition && competition[Utils.firstKey(competition)].game[startTimeKey];
  const gameName = competition && competition[Utils.firstKey(competition)].name;

  return (
    <div className="market-content">
      <div className="market-content-header">
        <p className="sport-title">{gameName}</p>
        {marketData && Object.keys(marketData.market).map((item, i) => (
          <p className="market-content-header-item" key={i}>{headerItem[i]}</p>
        ))}
      </div>

      <div className="market-content-body">
        <div className="player-names">
          {marketData &&
            marketData.info.virtual.map((item, i) => (
              <p key={i}>
                <embed src={`${baseURL}/${item.Number}.svg`} />
                <span>{item.AnimalName}</span>
              </p>
            ))}
        </div>

        {marketData &&
          Object.keys(marketData.market).map((item, n) => (
            <div className="coefficient" key={n}>
              {Object.keys(marketData.market[item].event).map(
                (eventItem, i) => (
                  <div key={i}>
                    <p
                      className={
                        marketData.market[item].event[eventItem].is_Max
                          ? "maxValue"
                          : marketData.market[item].event[eventItem].is_Min
                          ? "minValue"
                          : ""
                      }
                    >
                      {marketData.market[item].event[eventItem].price.toFixed(2)}
                    </p>
                  </div>
                )
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default memo(Market);
