import {
  AUTHORIZATION_RID,
  PARTNER_CONFIG_RID,
  SPORTS_RID,
  CURRENT_LEAGUE_RID,
  GAME_RID,
  REGION_RID,
  LAST_RESULTS_RID,
  AUTO_DATA_UPDATE_RID,
  MARKET_RID,
  LEAGUE_ROUNDS_RID,
  WRESTLING_RID,
  PROFILE_RID,
  GET_SERVER_TIME_RID,
  GET_VIDEO_URL_RID,
} from "./RequestDatas";
import Utils from "../utils/Utils";

const SOCKET_URL = "wss://eu-swarm-virtual-ws.betconstruct.com/";
const SOCKET_URL_STAGING = "wss://swarm-virtuals-staging.betconstruct.com/";

let webSocket;

export default class RequestManager {
  static SOCKET_CLOSED_EVENT = "SOCKET_CLOSED_EVENT";
  static AUTHORIZATION_EVENT = "AUTHORIZATION_EVENT";
  static PARTNER_DATA_EVENT = "PARTNER_DATA_EVENT";
  static BONUS_DATA_EVENT = "BONUS_DATA_EVENT";
  static PROFILE_DATA_EVENT = "PROFILE_DATA_EVENT";
  static SPORTS_DATA_EVENT = "SPORTS_DATA_EVENT";
  static REGION_DATA_EVENT = "REGION_DATA_EVENT";
  static LEAGUE_DATA_EVENT = "LEAGUE_DATA_EVENT";
  static GAME_DATA_EVENT = "GAME_DATA_EVENT";
  static LAST_RESULTS_EVENT = "LAST_RESULTS_EVENT";
  static MARKET_DATA_EVENT = "MARKET_DATA_EVENT";
  static LEAGUE_ROUNDS_DATA_EVENT = "LEAGUE_ROUNDS_DATA_EVENT";
  static WRESTLING_DATA_EVENT = "WRESTLING_DATA_EVENT";
  static CASH_OUT_EVENT = "CASH_OUT_EVENT";
  static CASH_OUT_SUBSCRIBE_EVENT = "CASH_OUT_SUBSCRIBE_EVENT";
  static GET_SERVER_TIME_EVENT = "GET_SERVER_TIME_EVENT";
  static GET_VIDEO_URL_EVENT = "GET_VIDEO_URL_EVENT";

  static AUTO_GAME_DATA = "AUTO_GAME_DATA";
  static AUTO_MARKET_DATA = "AUTO_MARKET_DATA";
  static AUTO_BALANCE_DATA = "AUTO_BALANCE_DATA";
  static AUTO_REMOVE_GAME_DATA = "AUTO_REMOVE_GAME_DATA";
  static AUTO_SCORES_DATA = "AUTO_SCORES_DATA";
  static AUTO_LEAGUE_TABLE_DATA = "AUTO_LEAGUE_TABLE_DATA";

  static instance = null;

  constructor() {
    this.setupWebSocket();
  }

  static getInstance() {
    if (RequestManager.instance === null) {
      RequestManager.instance = new RequestManager();
    }

    return RequestManager.instance;
  }

  setupWebSocket() {
    const socketUrl =
      Utils.STAGING_MODE || Utils.DEV_MODE ? SOCKET_URL_STAGING : SOCKET_URL;
    webSocket = new WebSocket(socketUrl);

    webSocket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      const requestID = response.rid.toString();
      let socketEvent;

      switch (requestID) {
        case AUTHORIZATION_RID:
          socketEvent = new CustomEvent(RequestManager.AUTHORIZATION_EVENT, {
            detail: response,
          });
          break;
        case PARTNER_CONFIG_RID:
          socketEvent = new CustomEvent(RequestManager.PARTNER_DATA_EVENT, {
            detail: response,
          });
          break;
        case PROFILE_RID:
          socketEvent = new CustomEvent(RequestManager.PROFILE_DATA_EVENT, {
            detail: response,
          });
          break;
        case SPORTS_RID:
          socketEvent = new CustomEvent(RequestManager.SPORTS_DATA_EVENT, {
            detail: response,
          });
          break;
        case CURRENT_LEAGUE_RID:
          socketEvent = new CustomEvent(RequestManager.LEAGUE_DATA_EVENT, {
            detail: response,
          });
          break;
        case GAME_RID:
          socketEvent = new CustomEvent(RequestManager.GAME_DATA_EVENT, {
            detail: response,
          });
          break;
        case REGION_RID:
          socketEvent = new CustomEvent(RequestManager.REGION_DATA_EVENT, {
            detail: response,
          });
          break;
        case LAST_RESULTS_RID:
          socketEvent = new CustomEvent(RequestManager.LAST_RESULTS_EVENT, {
            detail: response,
          });
          break;
        case MARKET_RID:
          socketEvent = new CustomEvent(RequestManager.MARKET_DATA_EVENT, {
            detail: response,
          });
          break;
        case LEAGUE_ROUNDS_RID:
          socketEvent = new CustomEvent(
            RequestManager.LEAGUE_ROUNDS_DATA_EVENT,
            { detail: response }
          );
          break;
        case WRESTLING_RID:
          socketEvent = new CustomEvent(RequestManager.WRESTLING_DATA_EVENT, {
            detail: response,
          });
          break;
        case GET_SERVER_TIME_RID:
          socketEvent = new CustomEvent(RequestManager.GET_SERVER_TIME_EVENT, {
            detail: response,
          });
          break;
        case GET_VIDEO_URL_RID:
          socketEvent = new CustomEvent(RequestManager.GET_VIDEO_URL_EVENT, {
            detail: response,
          });
          break;
        case AUTO_DATA_UPDATE_RID:
          this.handleAutoEvent(response);
          return;
        default:
        //console.log('REQUEST ID NOT DEFINED: ', response);
      }

      if (socketEvent) {
        webSocket.dispatchEvent(socketEvent);
      }
    };

    webSocket.onclose = () => {
      RequestManager.instance = null;
      webSocket.dispatchEvent(
        new CustomEvent(RequestManager.SOCKET_CLOSED_EVENT)
      );
      webSocket.close();
    };
  }

  handleAutoEvent({ data }) {
    const dataObjects = Object.keys(data).map((key) => data[key]);
    let dataString;
    let event;

    dataObjects.map((dataObject) => {
      dataString = JSON.stringify(dataObject);
      //console.log('>> LIVE UPDATE:', dataString);

      // CHECK REMOVE GLOBAL SPORT CASE(bug from swarm side).
      if (
        dataObject["sport"] &&
        dataObject["sport"][Utils.firstKey(dataObject["sport"])] === null
      ) {
        return;
      }

      if (dataString.includes("market")) {
        event = new CustomEvent(RequestManager.AUTO_MARKET_DATA, {
          detail: dataObject,
        });
      } else if (
        dataString.includes("homescore") ||
        dataString.includes("awayscore") ||
        dataString.includes("team1_name") ||
        dataString.includes("team1_region") ||
        dataString.includes("team2_name") ||
        dataString.includes("team2_region")
      ) {
        // For handling on League or Wrestling.
        event = new CustomEvent(RequestManager.AUTO_SCORES_DATA, {
          detail: dataObject,
        });
      } else if (dataString.includes("TeamName")) {
        event = new CustomEvent(RequestManager.AUTO_LEAGUE_TABLE_DATA, {
          detail: dataObject,
        });
      } else if (dataString.includes("type")) {
        event = new CustomEvent(RequestManager.AUTO_REMOVE_GAME_DATA, {
          detail: dataObject,
        });
      } else if (
        dataString.includes("game") &&
        !dataString.includes("competition")
      ) {
        event = new CustomEvent(RequestManager.AUTO_GAME_DATA, {
          detail: dataObject,
        });
      } else if (dataString.includes("profile")) {
        event = new CustomEvent(RequestManager.AUTO_BALANCE_DATA, {
          detail: dataObject,
        });
      } else {
        //console.log('>> NOTE DEFINED LIVE DATA:', JSON.stringify(data));
      }

      try {
        webSocket.dispatchEvent(event);
      } catch {
        //console.log('>> FAILED DISPATCHING LIVE EVENT:', dataString);
      }
    });
  }

  get webSocket() {
    return webSocket;
  }
}
