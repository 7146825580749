import moment from "moment-timezone";
import ThemeManager, {
  BBIN_THEME_DATA,
  VBET_THEME_DATA,
} from "../theme/ThemeManager";

export default class Utils {
  static DEV_MODE = process.env.NODE_ENV === "development";
  static STAGING_MODE = window.document.domain.indexOf("vs-stage") !== -1;

  static IS_BBIN = document.referrer.indexOf("betcoapps") !== -1;
  static IS_VBET = document.referrer.indexOf("vbet") !== -1;

  static getUniqNumber() {
    return Date.now() * Math.floor(Math.random() * 100) * Math.random();
  }

  /**
   *
   * @param timestamp
   * @returns {string} time in "10:30:23" format
   */
  static timestampToUTC(timestamp) {
    const date = new Date(timestamp * 1000);
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();
    const seconds = "0" + date.getSeconds();

    return hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
  }

  /**
   *
   * @param timestamp
   * @param bySeconds format of result
   * @returns {string} time in "10:30" format
   */
  static timestampToLocalTime(timestamp, bySeconds) {
    const offset = new Date(timestamp).getTimezoneOffset();
    const utcString = new Date(timestamp * 1000 - offset).toUTCString();
    const format = bySeconds ? "HH:mm:ss" : "HH:mm";

    return moment.utc(utcString).local().format(format);
  }

  /**
   *
   * @param timestamp
   * @param bySeconds format of result
   * @param hardFormat ignore bySeconds param and hard set param value.
   */
  static toLocalTime(timestamp, bySeconds, hardFormat) {
    const offset = new Date(timestamp).getTimezoneOffset();
    const utcString = new Date(timestamp * 1000 - offset).toUTCString();
    const format = hardFormat ? hardFormat : bySeconds ? "HH:mm:ss" : "HH:mm";

    return moment.utc(utcString).local().format(format);
  }

  static getTimesDifference = (startTime, endTime) => {
    const formattedEndTime = moment(startTime, "HH:mm:ss");
    const formattedCurrentTime = moment(endTime, "HH:mm:ss");
    const timeLeft = formattedEndTime.diff(formattedCurrentTime);

    return moment.utc(timeLeft).format("mm:ss");
  };

  /**
   * @returns object first key
   */
  static firstKey(object) {
    return object ? Object.keys(object)[0] : "";
  }

  /**
   * @returns object first key
   */
  static lastKey(object) {
    return object ? Object.keys(object)[Object.keys(object).length - 1] : "";
  }

  static toFractionalFormat = (amount) => {
    amount = amount.toFixed(2);

    const gcd = (a, b) => {
      return b < 0.0000001 ? a : gcd(b, Math.floor(a % b));
    };

    const len = amount.toString().length - 2;
    let denominator = Math.pow(10, len);
    let numerator = amount * denominator;
    const divisor = gcd(numerator, denominator);

    numerator /= divisor;
    denominator /= divisor;
    amount = Math.floor(numerator) + "/" + Math.floor(denominator);

    return amount;
  };

  /**
   * @param coef coefficient value.
   * @param coefType coefficient type.
   */
  static getFormattedCoef = (coef, coefType) => {
    let formattedCoef = coef;
    coefType = Number(coefType);

    switch (coefType) {
      case 1:
        formattedCoef = this.toFractionalFormat(formattedCoef - 1);
        return formattedCoef;
      case 2:
        formattedCoef =
          (coef >= 2 ? "+" : "-") +
          Math.floor(coef >= 2 ? (coef - 1) * 100 : -100 / (coef - 1));
        break;
      case 3:
        formattedCoef = coef - 1;
        break;
      case 4:
        formattedCoef =
          (coef >= 2 ? "" : "-") + (coef >= 2 ? -1 / (coef - 1) : coef - 1);
        break;
      case 5:
        formattedCoef =
          (coef >= 2 ? "" : "-") + (coef >= 2 ? coef - 1 : 1 / (coef - 1));
        break;
      default:
    }

    formattedCoef = formattedCoef.toString();
    const cropIndex =
      formattedCoef.indexOf(".") > 0 ? formattedCoef.indexOf(".") + 3 : 5;

    return formattedCoef.substring(0, cropIndex);
  };

  /**
   * Write data to local storage by parameters.
   * @param key local storage key.
   * @param data local storage data.
   * @param expirationSeconds local storage data expiration time by seconds.
   */
  static writeStorage = (key, data, expirationSeconds) => {
    try {
      localStorage.setItem(key, JSON.stringify(data));

      if (expirationSeconds) {
        const date = new Date();
        const schedule = Math.round(
          date.setSeconds(date.getSeconds() + expirationSeconds) / 1000
        );

        localStorage.setItem(key + "_expiration", JSON.stringify(schedule));
      }
    } catch {
      console.log("ERROR: Write to local storage by key:", key);
    }
  };

  static getStorageData = (key, widthExpiration) => {
    try {
      if (widthExpiration) {
        const currentTime = Math.round(Date.now() / 1000);
        const storedTime = parseInt(localStorage.getItem(key + "_expiration"));

        if (storedTime && currentTime > storedTime) {
          localStorage.setItem(key, null);
          return [];
        }
      }

      return JSON.parse(localStorage.getItem(key));
    } catch {
      console.log("ERROR: Get from local storage by key:", key);
    }
  };

  static blockInvalidNumbers = (e) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  static correctBetInputValue = (e) => {
    let value = e.currentTarget.value.toString();
    const pointIndex = value.indexOf(".");

    if (pointIndex !== -1 && value.length > pointIndex + 3) {
      e.currentTarget.value = value.slice(0, pointIndex + 3);
    } else if (value[0] === "0" && value[1] !== ".") {
      e.currentTarget.value = 0;
    }
  };

  static blockInvalidPastedNumbers = (e) => {
    const cbValue = e.clipboardData.getData("Text");

    if (
      cbValue.includes("E") ||
      cbValue.includes("e") ||
      cbValue.includes("+") ||
      cbValue.includes("-")
    ) {
      e.preventDefault();
    }
  };

  /**
   * Apply toUpperCase native method, if the language has capital letters.
   * @activeLanguage the application current language.
   */
  static upperCase = (word, activeLanguage) => {
    const notHaveCapitalList = ["geo"];
    const haveCapitalLetters =
      notHaveCapitalList.indexOf(activeLanguage) === -1;

    return haveCapitalLetters ? word.toUpperCase() : word;
  };

  static getBinomalCoef = (m, n) => {
    const mFact = Utils.factorial(m);
    const nFact = Utils.factorial(n);
    const minusFact = Utils.factorial(n - m);
    const temp = mFact * minusFact;

    return nFact / temp;
  };

  static getSubs = (arr, m) => {
    if (m === 0) return [];

    const n = arr.length;
    const a = [];
    const result = [];

    for (let i = 0; i < m; i++) {
      a[i] = i + 1;
    }

    let p = m;
    while (p >= 0) {
      let r = 1;
      for (let j = 0; j < a.length; j++) {
        r = r * arr[a[j] - 1];
      }

      result.push(r);
      p = a[m - 1] === n ? p - 1 : m - 1;

      if (p >= 0) {
        for (let i = m - 1; i >= p; i--) {
          a[i] = a[p] + i - p + 1;
        }
      }
    }

    return result;
  };

  static factorial = (n) => {
    return n !== 1 ? n * Utils.factorial(n - 1) : 1;
  };

  static applyApplicationTheme = (themeData) => {
    if (Utils.IS_BBIN) {
      ThemeManager.applyTheme(BBIN_THEME_DATA);
    } else if (Utils.IS_VBET) {
      ThemeManager.applyTheme(VBET_THEME_DATA);
    } else if (themeData) {
      ThemeManager.applyTheme({
        mainBG: themeData["background-color"],
        color0: themeData["color0"],
        color1: themeData["color1"],
        color2: themeData["color2"],
        color3: themeData["color3"],
        color4: themeData["color4"],
        color5: themeData["color5"],
        color6: themeData["color6"],
        asset1: themeData["line-color"],
        asset2: themeData["place-bet"],
        asset3: themeData["place-bet-light"],
        asset4: themeData["text-buttons-color"],
      });
    }
  };
}
